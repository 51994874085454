import React from "react";
import { Link } from "react-router-dom";
import { Button, Box, Typography, Container } from "@mui/material";

const Footer = () => {
  return (
    <Box style={{ maxWidth: '100vw', backgroundColor: '#f54747' }}>
      <Container style={{ maxWidth: 'xl' }}>
        <Box display="flex" justifyContent="space-between" py={2}>
          <Typography style={{ color: '#FFE6E6', fontWeight: 600, textDecoration: 'none' }}>© 2025 Foody MMC</Typography>

          <Box display="flex" flexDirection={'column'} textAlign={'left'} gap={2}>
            <Typography style={{ color: '#FFE6E6', fontWeight: 600, textDecoration: 'none' }} component={Link} to="/user-terms">İstifadəçi şərtləri</Typography>
            <Typography style={{ color: '#FFE6E6', fontWeight: 600, textDecoration: 'none' }} component={Link} to="/privacy-policy">Məxfilik siyasəti</Typography>
            <Typography style={{ color: '#FFE6E6', fontWeight: 600, textDecoration: 'none' }} component={Link} to="/return-policy">Geri qaytarma şərtləri</Typography>
          </Box>

          <Box display="flex" flexDirection={'column'} textAlign={'left'} gap={2}>
            <Typography style={{ color: '#FFE6E6', fontWeight: 600, textDecoration: 'none' }} component={Link} to="/join">Restoran kimi qoşul</Typography>
            <Typography style={{ color: '#FFE6E6', fontWeight: 600, textDecoration: 'none' }} component={Link} to="/delete-account">Hesabın silinməsi</Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
