import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Join from "./pages/Join";
import Contact from "./pages/Contact";
import UserTerms from "./pages/UserTerms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
import StoreRedirect from "./pages/StoreRedirect";
import DeleteAccount from "./pages/DeleteAccount";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Container, ThemeProvider, Box } from "@mui/material";
import theme from "./components/Theme";

function App() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: '100vh' }}>
      <ThemeProvider theme={theme}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/join" element={<Join />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/user-terms" element={<UserTerms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route path="/get-app" element={<StoreRedirect />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </Box>
  );
}

export default App;
