import { Box, Typography } from "@mui/material";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Box sx={{ margin: 'auto', maxWidth: 'calc(100vw - 400px)' }}>
      <Typography marginTop={'50px'} variant="h5">Məxfilik Siyasəti</Typography>
      <p style={{
        textAlign: 'left',
        whiteSpace: 'pre-wrap', // Ensures new lines are rendered
        wordWrap: 'break-word', // Helps prevent overflow
      }} className="mt-4">
        {
          `1.	ÜMUMİ ŞƏRTLƏR
•	Bu Məxfilik Siyasəti Tətbiqdən istifadə zamanı İstifadəçinin təqdim etdiyi şəxsi məlumatların işlənilməsi ilə bağlı siyasətimizi bildirir və İstifadəçi Müqaviləsinin ayrılmaz tərkib hissəsi hesab olunur. Siyasətlə diqqətlə tanış olmağınızı və Tətbiqdən istifadə zamanı nəzərə almağınızı xahiş edirik. 
•	Tətbiqdən istənilən formada istifadə etməklə İstifadəçi özü haqqında şəxsi məlumatları və istənilən başqa informasiyanı könüllü şəkildə Foody-ə ötürür və həmin məlumatların və informasiyanın Foody tərəfindən işlənməsinə və ya işlənməsi üçün üçüncü şəxslərə ötürülməsinə öz razılığını bildirir.
•	Siyasətə Foody tərəfindən birtərəfli qaydada istənilən vaxt dəyişiklik edilə bilər. Bu halda dəyişiklik barədə Tətbiq vasitəsilə İstifadəçilərə məlumat veriləcəkdir. Tətbiqdən istifadəyə davam etməklə İstifadəçi dəyişikliklərlə razı olduğunu ifadə edir. Dəyişikliklərlə və Məxfilik Siyasəti ilə razı olmayan İstifadəçilər Tətbiqdən istifadəni dayandırmaldırlar.
•	İstifadəçi Müqaviləsində istifadə olunan anlayışlar bu Siyasətdə də eyni mənanı ifadə edir.
•	Foody İstifadəçiyə məxsus məlumatları 2 yolla toplayır – İstifadəçinin özü tərəfindən daxil edilir və bu məlumatlar Foody tərəfindən İstifadəçiyə xidmət göstərilə bilməsi və Sifarişçinin Tətbiqdən istifadə edə bilməsi üçün vacibdir. İlk 3 sırada olan məlumatlar.
•	Digər yol isə, İstifadəçinin Tətbiqdən istifadə etməsi zamanı Foody tərəfindən məlumatların əldə edilməsidir. Həmin məlumatları İstifadəçi özü təqdim etməsə belə bu Məxfilik Siyasətini təsdiq edərək Tətbiqdən istifadə etməyə davam etdiyi təqdirdə həmin məlumatların da Foody tərəfindən toplanmasına razılıq verir.
•	Bu Siyasətdə Şəxsi məlumat dedikdə İstifadəçinin xidmətlərimizdən istifadəsiylə əlaqədar olaraq əldə edilən və İstifadəçinin kimliyini birbaşa və ya dolayısı ilə müəyyənləşdirməyə imkan verən istənilən məlumat, o cümlədən, İstifadəçinin adı, soyadı, iş yeri, ünvanı, mobil nömrəsi, e-poçtu, fotoşəkli və s. başa düşülür.
•	Foody tərəfindən İstifadəçilərin aşağıdakı məlumatları toplanır:
1)	İstifadəçinin adı, soyadı, iş yeri, çatdırılma ünvanları, mobil nömrəsi, e-poçtu (Bu yalnız istifadəçinin istəyi ilə sifarişin çekinin göndərilməsi üçün lazım olur. Ola bilər ki, gələcəkdə Google hesabı ilə daxil olmaq və sifariş vermək mümkün olsun.), fotoşəkli və İstifadəçinin Tətbiqdən istifadə edərkən daxil etdiyi digər məlumatlar (şəxsi məlumatlar);
2)	Sifarişlərlə bağlı məlumatlar: istifadəçinin yemək seçimləri, qidalanması ilə bağlı daxil etdiyi xüsusi məlumatlar, sifarişlərin tarixi, vaxtı, sayı, məbləği, allergenlərə həssaslığı barədə məlumatlar, yemək saatları və s.;
3)	İstifadəçinin restoranlarla bağlı geribildirimləri, verdiyi xallar, sorğularda iştirakı ilə bağlı məlumatlar;
4)	Coğrafi məlumatlar: İstifadəçinin Tətbiqdən istifadə etdiyi və sifarişləri həyata keçirdiyi məkanlar;
5)	İstifadəçinin Tətbiq üzərindəki reytingi;
6)	İstifadəçinin kuryer və restoranla Tətbiq vasitəsilə zəng (Yalnız zəng tarixçəsi  toplanır, danışıqlar qeydə alınmır) və yazışmaları;
7)	Sifariş tarixçəsi;
8)	Ödəniş üsulları və ödəniş tarixçəsi;
9)	Tətbiqdən istifadə olunan cihaz və ya sayta daxil olunan brauzerlə bağlı məlumatlar (Bu bənd Satıcı və Kuryerə də aiddir. Çünki, onlar da tətbiqin istifadəçisi sayılır);
10)	Tətbiqdən istifadənin Foody tərəfindən ban olunması və ya dayandırılması halında bu barədə məlumatlar;
11)	Tətbiqdən istifadə xüsusiyyətləri, klikləmə və naviqasiya məlumatları;
12)	Tətbiqdən və saytdan istifadə zamanı çərəzlər və digər oxşar texnologiyalar tərəfindən toplanan məlumatları.
•	Bu maddənin 2-11-ci bəndlərindəki məlumatlar “Digər məlumatlar” olaraq adlandırılacaq. Digər məlumatlar adətən İstifadəçinin kimliyini müəyyən etməyə imkan vermir. Əgər Digər məlumatlardan İstifadəçinin kimliyi məlum olarsa həmin məlumatlara Şəxsi məlumatlar kimi yanaşılacaqdır.
•	Foody İstifadəçinin bank və kart məlumatlarını toplamır. 
•	Foody tərəfindən Kuryerlərin aşağıdakı məlumatları toplanır:
1)	Kuryerin adı, soyadı, iş yeri, ünvanı (hal-hazırda olduğu məkan ?), mobil nömrəsi, e-poçtu (bu toplanılmır), fotoşəkli;
2)	Kuryerlər proqrama daxil olduqda coğrafi məlumatlar;
3)	Yerinə yetirilən Sifarişlərin təfərrüatları, rəy reytinqləri və hər gün Kuryerin xidmət göstərmə müddəti;
4)	Müştərilərlə Tətbiq vasitəsilə yazışmalar;
5)	Müştəri şikayətləri barədə məlumatlar;

•	Foody Tərəfdaşların aşağıdakı məlumatlarını toplayır:
1)	Tətbiq vasitəsilə həyata keçirilən satışlarla bağlı biznes məlumatları (həyata keçirilən satış, xidmət göstərildiyi ərazi, əldə olunan gəlir miqdarı və s.);
2)	Sifarişlərin hazırlanması ilə bağlı məlumatlar;
3)	Müştərilərlə Tətbiq üzərindən həyata keçirilən yazışmalar;
4)	Reytinq məlumatları;
5)	Müştəri şikayətləri (geribildirimləri ?) barədə məlumatlar;

•	Foody İstifadəçi və Kuryer tərəfindən daxil edilən və ya Tətbiq tərəfindən birbaşa əldə edilən onlara məxsus məlumatların dəqiqliyi və düzgünlüyü barədə məsuliyyət daşımır.
•	Şəxsi məlumatların və digər informasiyaların işlənməsi dedikdə fərdi məlumatların toplanması, sistemləşdirilməsi, təzələnməsi, dəyişdirilməsi, çıxarılması, adsızlaşdırılması, saxlanması, ötürülməsi və məhv edilməsi başa düşülür.
•	Foody şəxsi məlumatları və digər informasiyanı aşağıdakı məqsədlərlə işləyir:
1)	Sifarişlərin yerinə yetirilməsi və icra oluna bilməsi üçün İstifadəçi, Tərəfdaş və Kuryerlə bağlı gərəkli olan məlumatları bir-birinə ötürür. Daha ətraflı:
a.	İstifadəçinin(“Müştəri” olsa, daha yaxşı olar) Sifarişlə bağlı məlumatlarını, istifadəçi hesabında əks olunuş fərdi məlumatlarını (ad, soyad, əlaqə vasitəsi, ünvan, fotoşəkil (Profil şəkli)) Restorana və kuryerə ötürür.
b.	Restoranın(“Satıcı” olsa, daha yaxşı olar) və Kuryerin Tətbiqdə özü ilə bağlı daxil etdiyi məlumatları, Restoranın Sifarişi hazırlama mərhələlərini, Kuryerin Sifarişi çatdırma mərhələləri və həmin anda coğraafi məlumatlarını İstifadəçiyə ötürür.
2)	İstifadəçi, kuryer və Tərəfdaşla kommunikasiya qurmaq üçün istifadə edir;
3)	Tətbiqin və Tətbiq vasitəsilə göstərilən xidmətin təkmilləşdirilməsi məqsədilə statistik məlumatlar çıxarır və onları analiz edir;
4)	Foody-nin və/ və ya Tərəfdaş restoranların reklamı, aksiyalar barədə məlumat verilməsi;
5)	Tətbiqdə İstifadəçilərə ən uyğun restoranların onlara göstərilmə ardıcıllığının təmin olunması;
6)	 Qüvvədə olan qanunvericiliklə tələb olunan hallar.

•	1.9 Foody İstifadəçilər tərəfindən təqdim edilmiş şəxsi məlumat və ya informasiyanı, İstifadəçinin belə icazəni verdiyi hal həmçinin də İstifadəçi razılaşması ilə və ya qanunvericiliklə nəzərdə tutulmuş hallar istisna olmaqla, aidiyyati olmayan üçüncü şəxslərə vermir.
•	1.11 Foody İstifadəçilərdən topladığı şəxsi məlumatları və digər məlumatları satmır və icarəyə vermir.

ÇƏRƏZLƏR VƏ DİGƏR OXŞAR TEXNOLOGİYALAR
•	Foody İstifadəçilərin Digər məlumatlarını toplamaq və saxlamaq üçün vebsayt məlumatlarının saxlanması və veb və proqram telemetriyasından istifadə daxil olmaqla, müxtəlif texnologiyalardan istifadə edə bilər. Bu, bizə xidmətlərimizi təkmilləşdirməyə, İstifadəçilərimizin fərdi maraqlarına uyğunlaşdırmağa, İstifadəçilərimizə daha yaxşı xidmət göstərməyə kömək edir. Çərəzlər və digər vebsayt məlumatları İstifadəçilərin cihazına və ya fayllarına zərər vermir.
•	İstifadəçi çərəz seçimlərini Foody.com(foody-delivery.com və foody.az) veb saytındakı çərəz banneri vasitəsilə, eləcə də Tətbiqdəki məxfiliklə bağlı tənzimləmələr vasitəsilə idarə edə bilər.
Çərəzlər barədə başqa nə yazmaq olar?

NAVİQASİYA VƏ KLİKLƏMƏ – bu məlumatlar toplanır?
Platformadan istifadə edərkən, baxdığınız səhifələr, kliklədiyiniz düymə və əlaqələrə dair naviqasiya və klik məlumatları, bir səhifədə qaldığınız müddət, sifariş vermə müddətiniz kimi məlumatları əldə etmək üçün saxlanılır. Həmin məlumatlar sizin kimliyinizi ortaya çıxartmır və birbaşa olaraq öz adınızla əlaqələndirilmir.

ŞƏXSİ VƏ DİGƏR MƏLUMATLARIN MÜHAFİZƏSİ
Biz şəxsi məlumatlarınızın təhlükəsizliyini qorumağa ciddi yanaşır, eləcə də bu istiqamətdə müəyyən təşkilati və texniki işlər görürük. Cihazınız və serverlərimiz arasında ötürülən bütün məlumatlar SSL/TLS şifrələməsi ilə qorunur, bu da məlumatların üçüncü şəxslər tərəfindən ələ keçirilməsinin qarşısını alır. Bütün verilənlər bazasında şifrələmə tətbiq edilmir, lakin istifadəçi şifrələri təhlükəsiz şəkildə hash və salt üsulu ilə qorunur. Həssas məlumatlara giriş yalnız səlahiyyətli şəxslərə verilir və təhlükəsizlik tədbirləri ilə qorunur. Biz məlumat təhlükəsizliyini qorumaq üçün daima monitorinq aparır, yeniləmələr edir və mümkün risklərin qarşısını almağa çalışırıq. Bütün bu tədbirlərə baxmayaraq, heç bir sistem tam təhlükəsiz deyil.

 MÜBAHİSƏLƏRİN HƏLLİ

Məxfilik Siyasətinin həyata keçirilməsindən yarana biləcək hər cür mübahisələrin həlli Azərbaycan Respublikasının mövcud qanunvericiliyinə və Azərbaycan Respublikasının tərəfdar çıxdığı beynəlxalq müqavilələrin şərtlərinə əsasən həll edilir.

MƏLUMATLARIN SAXLANMASI MÜDDƏTİ

Foody şəxsi məlumatlarınızı qanunla icazə verilən və Xidmətlərin və ya onların müvafiq hissələrinin təmin edilməsi məqsədləri üçün zəruri olan müddətdə saxlayır. Saxlama müddəti məlumatın xarakterindən və emal məqsədlərindən asılıdır. Buna görə də maksimum müddət istifadəyə görə dəyişə bilər.

İSTİFADƏÇİLƏRİN HÜQUQLARI
Məlumat hüququ – İstifadəçilərin Foody tərəfindən işlənmiş şəxsi məlumatlarınıza daxil olmaq və bu barədə məlumat almaq hüququnuz var. İstifadəçi Foody Tətbiqindıki istifadəçi hesabı vasitəsilə müəyyən məlumatlara baxa və ya Foody ilə əlaqə saxlayaraq şəxsi məlumatlarının surətini tələb edə bilər.
Düzəliş hüququ – İstifadəçi Foody-də olan yanlış və qeyri-dəqiq məlumatların düzəldilməsini tələb edə bilər.
Silmək hüququ – İstifadəçi şəxsi məlumatlarının bir qisminin və ya hamısının daimi olaraq silinməsini tələb edə bilər. Məlumatların silinməməsi üçün Foody-nin hüquqi əsası olduğu hallar istisna olmaqla bu cür tələb yerinə yetirilməlidir.
İstifadəçi fərdi məlumatlarının toplanması üçün verdiyi razılığını istənilən an geri çəkə bilər.
İstifadəçi məlumatlarının hansı məqsədlərlə istifadə olunması barədə ətraflı məlumat tələb edə bilər.
İstifadəçi məlumatların hansı ötürücülərə ötürülməsi barədə məlumat tələb edə bilər.
informasiya sistemində özü barəsində toplanılmış və işlənilən fərdi məlumatların əldə edilməsi mənbələri barədə məlumat almaq, həmin məlumatların qanuniliyinin sübuta yetirilməsini tələb etmək;

`
        }
      </p>
    </Box >
  );
};

export default PrivacyPolicy;
