import React from "react";

const ReturnPolicy = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold">Return Policy</h1>
      <p className="mt-4">
        Our return policy allows you to request a refund under certain conditions.
      </p>
    </div>
  );
};

export default ReturnPolicy;
