import { createTheme } from "@mui/material/styles";
import "@fontsource/open-sans"; // Import the font
import tinycolor from "tinycolor2";

// Function to adjust saturation in HSV
const adjustSaturation = (hex, saturationMultiplier) => {
  let color = tinycolor(hex).toHsv();
  color.s = Math.min(1, color.s * saturationMultiplier); // Adjust S percentage
  return tinycolor(color).toHexString();
};

// Base button color
const baseColor = "#F54747";

const theme = createTheme({
  typography: {
    fontFamily: "Open Sans, sans-serif", // Apply Open Sans globally
    h1: { fontWeight: 700, color: '#333' },
    h2: { fontWeight: 600, color: '#333' },
    h5: { fontWeight: 700, color: '#333' },
    body1: { fontWeight: 400, color: '#333' },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          paddingLeft: "10px",
          paddingRight: "10px",
          textTransform: "none", // Keeps button text as provided (no uppercase)
          fontWeight: 600,
          backgroundColor: baseColor, // Default background color
          color: "#fff", // Default text color
          "&:hover": {
            backgroundColor: adjustSaturation(baseColor, 0.9), // Increase saturation by 20%
          },
          "&:active": {
            backgroundColor: adjustSaturation(baseColor, 1), // Reduce saturation by 20%
          },
        },
      },
    },
  },
});

export default theme;
