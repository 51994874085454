import React from "react";
import { Button, Box, Typography, Container } from "@mui/material";
import logo from "../assets/logo.svg";

const Home = () => {
  return (
    <Box sx={{ margin: 'auto', maxWidth: 'calc(100vw - 400px)' }} display="flex" justifyContent="space-between" textAlign={'center'} minHeight={'calc(100vh - 250px)'}>
      <Box display="flex" flexDirection={'column'} alignSelf={'center'} textAlign={'left'}>
        <Typography variant="h5">
          Daha çatdırılma və xidmət haqqı ödəmə!
        </Typography>
        <Typography variant="h5" style={{ wordWrap: 'break-word' }} marginTop={"10px"}>
          Yalnız yeməklərin qiymətini ödəməklə yaxınlıqdakı restoranlardan sifariş et.
        </Typography>
      </Box>
      <Box display="flex" flexDirection={'column'} alignSelf={'center'} textAlign={'left'}>
        <Typography variant="h5">
          Daha çatdırılma və xidmət haqqı ödəmə!
        </Typography>
        <Typography variant="h5" style={{ wordWrap: 'break-word' }} marginTop={"10px"}>
          Yalnız yeməklərin qiymətini ödəməklə yaxınlıqdakı restoranlardan sifariş et.
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;
