import React from "react";

const UserTerms = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold">User Terms</h1>
      <p className="mt-4">
        These are the terms and conditions for using our food delivery service.
      </p>
    </div>
  );
};

export default UserTerms;
