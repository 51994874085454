import React, { useState } from "react";

const Join = () => {
  const [restaurant, setRestaurant] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage(`Thank you, ${restaurant}, for joining!`);
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold">Join as a Restaurant</h1>
      <p className="mt-4">Partner with us to reach more customers.</p>
      <form onSubmit={handleSubmit} className="mt-4">
        <input 
          type="text" 
          placeholder="Restaurant Name" 
          value={restaurant}
          onChange={(e) => setRestaurant(e.target.value)}
          className="border p-2"
        />
        <button type="submit" className="ml-2 bg-red-500 text-white px-4 py-2">Join</button>
      </form>
      {message && <p className="mt-2 text-green-500">{message}</p>}
    </div>
  );
};

export default Join;
