import React from "react";

const Contact = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold">Contact Us</h1>
      <p className="mt-4">Email: support@fooddelivery.com</p>
      <p>Phone: +1234567890</p>
    </div>
  );
};

export default Contact;
