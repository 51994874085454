import { Box, Typography } from "@mui/material";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Box sx={{ margin: 'auto', maxWidth: 'calc(100vw - 400px)' }}>
      <Typography textAlign={'left'} marginTop={'50px'} variant="h5">Hesabın silinməsi</Typography>
      <p style={{
        textAlign: 'left',
        whiteSpace: 'pre-wrap', // Ensures new lines are rendered
        wordWrap: 'break-word', // Helps prevent overflow
      }} className="mt-4">
        {
          `Bizim tətbiqimizdə hesabınızı sildikdə, başqa istifadəçilər və ya satıcılarla müştərək olan məlumatlar anonimləşdirilib saxlanılmaqla bütün məlumatlar silinir.

İstifadəçi hesabın silinməsi üçün sorğu göndərir. Sorğu göndərildikdən sonra hesab 30 gün ərzində deaktiv edilir və tam silinmir. Bu müddət ərzində istifadəçi hesabı bərpa edə bilər. 30 gün tamamlandıqdan sonra hesabda olan fərdi məlumatlar anonimləşdirilir və/və ya tamamilə silinir.

Telefon nömrəsi, ad, e-poçt ünvanı və digər fərdi məlumatlar tamamilə silinir. Müştəri və ya satıcı ilə bağlı məlumatlar (sifarişlər, ödəniş tarixçəsi və s.) tam hesab silindikdən sonra da anonimləşdirilir. Hesab anonimləşdirildikdən sonra artıq istifadəçiyə aid edilə bilən məlumatlar mövcud olmur. Bu, istifadəçinin sistemdə artıq tanınmadığı və şəxsi məlumatlarının üçüncü tərəflər üçün əlçatan olmadığı mənasını verir.

İstifadəçilər tətbiq vasitəsilə hesablarının tamamilə silinməsinə nə qədər vaxt qaldığını izləyə bilərlər. Bunun üçün "Hesab və parametrlər (Ana səhifədə solda yuxarıda olan profil şəkli)" → "Parametrlər" → "Təhlükəli zona" bölməsinə daxil olun.

Əgər hər hansı sualınız və ya hesabınızın silinməsi ilə bağlı əlavə məlumat almaq istəyirsinizsə, bizimlə əlaqə saxlayın.
`
        }
      </p>
    </Box >
  );
};

export default PrivacyPolicy;
