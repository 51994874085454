import React from "react";
import { Link } from "react-router-dom";
import { Button, Box, Typography, Container } from "@mui/material";
import logo from "../assets/logo.svg";
import "@fontsource/belanosima";

const Navbar = () => {
  return (
    <Container style={{ maxWidth: 'xl', borderBottom: "1px solid #666" }}>
      <Box display="flex" justifyContent="space-between" alignItems={'center'} py={2}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <img style={{ 'width': '70px' }} src={logo} alt="Foody Logo" className="logo" />
          <Box style={{ textAlign: 'left', marginLeft: '10px', marginBottom: '5px' }}>
            <Typography style={{ fontFamily: 'Belanosima, sans-serif', fontSize: '24px' }} color="#f54747">Foody</Typography>
            <Typography fontSize={'16px'} fontWeight={700} marginTop={'-5px'}>Pulsuz yemək çatdırılması</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems={'center'} gap={3}>
          <Typography style={{ fontWeight: 600, textDecoration: 'none' }} component={Link} to="/">
            Ana səhifə
          </Typography>
          <Typography style={{ fontWeight: 600, textDecoration: 'none' }} component={Link} to="/about">
            Haqqımızda
          </Typography>
          <Typography style={{ fontWeight: 600, textDecoration: 'none' }} component={Link} to="/join">
            Restoran kimi qoşul
          </Typography>
          <Typography style={{ fontWeight: 600, textDecoration: 'none' }} component={Link} to="/contact">
            Əlaqə
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Navbar;
