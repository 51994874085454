import { useEffect } from "react";

const StoreRedirect = () => {
    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const androidAppPackage = "az.foody"; // Replace with your Android package name
        const iosAppId = "6475301798"; // Replace with your iOS App Store ID

        if (userAgent.includes("android")) {
            window.location.href = `https://play.google.com/store/apps/details?id=${androidAppPackage}`;
        } else if (userAgent.includes("iphone") || userAgent.includes("ipad")) {
            window.location.href = `https://apps.apple.com/app/id${iosAppId}`;
        } else {
            window.location.href = "https://yourappwebsite.com"; // Fallback URL for desktop users
        }
    }, []);

    return null; // This component does not render anything
};

export default StoreRedirect;
