import React from "react";
import logo from "../assets/logo.svg";

const About = () => {
  return (
    <div className="container">
      <img src={logo} alt="Foody Logo" className="logo" />
      <h1>Foody</h1>
      <h2>Xidmətlərimiz</h2>
      <p>Foody sizə rahat və pulsuz yemək çatdırılması təqdim edir.</p>
      <p>Bizim məqsədimiz keyfiyyətli restoran yeməklərini daha əlçatan etməkdir.</p>
    </div>
  );
};

export default About;
